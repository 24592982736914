import request from '@/plugins/axios'

export function refreshToken(data) {
  return request({
    url: `/api/v1/account/refresh_token`,
    method: 'post',
    data
  })
}

export function addTask(data) {
  return request({
    url: `/api/v1/task/add_task`,
    method: 'post',
    data
  })
}
