import request from '@/plugins/axios'

export function loginApi(data) {
  return request({
    url: `/api/v1/account/login`,
    method: 'post',
    data
  })
}

export function authLogin(data) {
  return request({
    url: `/api/v1/account/OAuthLogin`,
    method: 'post',
    data
  })
}

export function taskInterface() {
  return request({
    url: `/api/v1/task/interface`,
    method: 'get'
  })
}

export function logOut() {
  return request({
    url: '/auth/logout',
    method: 'get'
  })
}
