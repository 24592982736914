import axios from 'axios'
import { Message } from 'element-ui'
// import { MessageBox, Message } from 'element-ui'
import store from '@/store'
// import { getToken } from '@/utils/auth'

// create an axios instance
import Router from '../router/index.js'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000, // request timeout
})
// store.commit('user/SET_TOKEN')
// let token = store.state.user.token

service.interceptors.request.use(
  config => {
    let token = sessionStorage.getItem('token')
    config.headers['Authorization'] = 'Bearer ' + token
    // debugger
    return config
  },
  error => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const { status, data } = response

    return response
  },
  error => {
    const { status } = error.response
    const { config } = error
    if (status === 401) {
      if (config.url !== '/api/v1/account/refresh_token') {
        store.dispatch('user/resetToken')
      } else {
        let source = sessionStorage.getItem('websiteSource')

        if (source) {
          if (source.indexOf('http') > -1) {
            window.location.href(source)
          } else {
            Router.push('/login')
          }
        } else {
          Router.push('/401')
        }
      }
    } else {
      let message = error.response.data.message
      Message.warning(message ? message : '错误！')
    }
  }
)

export default service
