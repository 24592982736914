import { Message } from 'element-ui'
import { authLogin, taskInterface, loginApi } from '@/api/user'
import { refreshToken } from '@/api/global'

const getDefaultState = () => {
  return {
    // token: getToken(),
    token: '',
    refresh_token: '',
    name: '',
    avatar: '',
    userInfo: {},
    interface: {}
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: state => {
    Object.assign(state, getDefaultState())
  },
  SET_TOKEN: (state, token) => {
    state.token = sessionStorage.getItem('token')
  },
  SET_refresh_TOKEN: (state, refresh_token) => {
    state.refresh_token = sessionStorage.getItem('refresh_token')
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_INFO(state) {
    let obj = JSON.parse(sessionStorage.getItem('userInfo'))
    state.userInfo = obj
  },
  SET_interface(state) {
    let obj = JSON.parse(sessionStorage.getItem('taskInterface'))
    state.interface = obj
  }
}

const actions = {
  loginPage({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      loginApi(userInfo)
        .then(response => {
          const { data } = response
          if (data.data) {
            let { token, refresh_token } = data.data
            sessionStorage.setItem('token', token)
            commit('SET_TOKEN', token)
            sessionStorage.setItem('refresh_token', refresh_token)
            commit('SET_refresh_TOKEN', refresh_token)
            sessionStorage.setItem('userInfo', JSON.stringify(data.data))
            commit('SET_INFO')
            resolve()
          } else {
            Message.error('登陆失败')
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  // user login
  login({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      authLogin(userInfo)
        .then(response => {
          const { data } = response
          if (data.data) {
            let { token, refresh_token } = data.data
            sessionStorage.setItem('token', token)
            commit('SET_TOKEN', token)
            sessionStorage.setItem('refresh_token', refresh_token)
            commit('SET_refresh_TOKEN', refresh_token)
            sessionStorage.setItem('userInfo', JSON.stringify(data.data))
            commit('SET_INFO')
            resolve()
          } else {
            Message.error('登陆失败')
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  // get user info
  getTask({ commit, state }) {
    return new Promise((resolve, reject) => {
      taskInterface().then(res => {
        // console.log(res)
        if (res.status == 200) {
          sessionStorage.setItem('taskInterface', JSON.stringify(res.data.data))
          commit('SET_interface')
          resolve(res)
        }
      })
    })
  },

  // user logout
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      // logout(state.token).then(() => {
      //   removeToken() // must remove  token  first
      //   resetRouter()
      //   commit('RESET_STATE')
      //   resolve()
      // }).catch(error => {
      //   reject(error)
      // })
      return null
    })
  },

  // remove token
  resetToken({ commit, state }) {
    return new Promise(resolve => {
      let obj = {
        refresh_token: state.refresh_token
      }
      refreshToken(obj).then(res => {
        if (res.status == 200) {
          let { token, refresh_token } = res.data.data
          sessionStorage.setItem('token', token)
          commit('SET_TOKEN', token)
          sessionStorage.setItem('refresh_token', refresh_token)
          commit('SET_refresh_TOKEN', refresh_token)
          window.location.reload()
        }
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
