<template>
  <div class="pagination-com" :style="{ background: boxBackground }">
    <el-pagination
      :style="{ 'text-align': textAlign, margin: margin, padding: padding }"
      :background="background"
      :pager-count="pagerCount"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="sizesArr"
      :page-size="size"
      :layout="layout"
      :total="total"
      :small="small"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    //   是否带背景
    background: {
      type: Boolean,
      default: true
    },
    // 分页位置（left\center\right）
    textAlign: {
      type: String,
      default: 'center'
    },
    // 分页组件布局
    layout: {
      type: String,
      default: 'total, sizes, prev, pager, next, jumper'
    },
    // 分页 数组
    sizesArr: {
      type: Array,
      default: () => {
        return [10, 20, 50, 100, 200]
      }
    },
    // 当前页
    currentPage: {
      type: Number,
      default: 1
    },
    // 总页数
    total: {
      type: Number,
      default: 0
    },
    // 最大页码按钮数
    pagerCount: {
      type: Number,
      default: 7
    },
    //  样式大小
    small: {
      type: Boolean,
      small: false
    },
    margin: {
      type: String,
      default: '0 0'
    },
    padding: {
      type: String,
      default: '10px 0'
    },
    boxBackground: {
      type: String,
      default: 'white'
    }
  },
  data() {
    return {}
  },
  computed: {
    size() {
      return this.sizesArr[0]
    }
  },
  methods: {
    handleSizeChange(val) {
      this.$emit('sizeChange', val)
    },
    handleCurrentChange(val) {
      this.$emit('currentChange', val)
    }
  }
}
</script>

<style lang="scss" scoped>
.pagination-com {
}
</style>
