<template>
  <div :class="{ 'has-logo': showLogo }" class="left-menu-style">
    <logo v-if="showLogo" :collapse="isCollapse" />
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        :default-active="activeMenu"
        :collapse="isCollapse"
        :background-color="variables.menuBg"
        :text-color="variables.menuText"
        :unique-opened="false"
        :active-text-color="variables.menuActiveText"
        :collapse-transition="false"
        @select="selectMenu"
        mode="vertical"
      >
        <sidebar-item
          v-for="route in routes"
          :key="route.path"
          :item="route"
          :base-path="route.path"
        />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Logo from './Logo'
import SidebarItem from './SidebarItem'
import variables from '@/styles/variables.scss'
import addRouteFun from '@/router/administrators'

export default {
  components: { SidebarItem, Logo },
  computed: {
    ...mapGetters(['sidebar']),
    routes() {
      const roles = window.localStorage.getItem('roles')
      let userRoutes = []
      if (roles && roles.indexOf('ADMIN') !== -1) {
        // 如果是管理员
        userRoutes = this.$router.options.routes
      } else {
        // 如果不是管理员，则剔除管理员的路由
        userRoutes = this.$router.options.routes.filter(function(route, index) {
          return route.name !== 'Manage'
        })
      }
      this.addList(userRoutes)
      return userRoutes
    },
    activeMenu() {
      const route = this.$route
      const { meta, path } = route
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
    showLogo() {
      return this.$store.state.settings.sidebarLogo
    },
    variables() {
      return variables
    },
    isCollapse() {
      return !this.sidebar.opened
    }
  },
  watch: {
    $route: {
      handler(n, o) {
        this.getBreadcrumb()
      },
      immediate: true
    }
  },
  created() {
    this.$store.commit('user/SET_INFO')
    this.$store.commit('user/SET_TOKEN')
    this.$store.commit('user/SET_refresh_TOKEN')
    this.$store.commit('user/SET_interface')

    addRouteFun(this.$router)
  },
  methods: {
    addList(userRoutes) {
      let typeData = sessionStorage.getItem('juHeRoles')
      let obj = {
        path: '/administrators',
        name: 'administrators',
        children: [
          {
            path: 'page',
            name: 'administratorsPage',
            meta: { title: '管理员', icon: 'el-icon-s-grid' }
          }
        ]
      }
      if (typeData === 'true') {
        userRoutes.push(obj)
      }
    },
    selectMenu(path, pathArr) {
      // let matched=this.$route.matched
    },
    getBreadcrumb() {
      let pathObj = this.$route

      let list = JSON.parse(sessionStorage.getItem('tagList'))
        ? JSON.parse(sessionStorage.getItem('tagList'))
        : []
      if (pathObj.meta && pathObj.meta.title) {
        if (
          !list.some(item => {
            return item.path == pathObj.path
          })
        ) {
          let a = {
            name: pathObj.meta.title,
            path: pathObj.path
          }
          let b = {}
          if (this.isEmptyObj(this.$route.query)) {
            b = this.$route.query
            if (this.$route.query.target_account) {
              a.name = this.$route.query.target_account
            }
          }

          a = Object.assign(a, b)
          list.push(a)
        }
      }

      sessionStorage.setItem('tagList', JSON.stringify(list))
      this.$store.commit('settings/SET_TABS')
    },
    isEmptyObj(obj) {
      for (let item in obj) {
        return true
      }
      return false
    }
  }
}
</script>
<style scoped>
.left-menu-style {
  box-shadow: 3px 0px 9px 0px #909090;
}
</style>
