import Vue from 'vue'
import VueRouter from 'vue-router'
import addRouteFun from './administrators'
import Layout from '@/layout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/index',
    name: 'indexParent',
    children: [
      {
        path: 'index',
        name: 'index',
        component: () => import('@/views/index/Index'),
        meta: { title: '首页', icon: 'el-icon-house' }
      }
    ]
  },
  {
    path: '/phone',
    component: Layout,
    name: 'phone',
    children: [
      {
        path: 'page',
        name: 'phonePage',
        component: () => import('@/views/globalView/Index'),
        meta: { title: '手机号', icon: 'el-icon-mobile-phone' }
      }
    ]
  },
  {
    path: '/email',
    component: Layout,
    name: 'email',
    children: [
      {
        path: 'page',
        name: 'emailPage',
        component: () => import('@/views/globalView/Index'),
        meta: { title: '邮箱', icon: 'el-icon-message' }
      }
    ]
  },
  {
    path: '/user',
    component: Layout,
    name: 'user',
    children: [
      {
        path: 'name',
        name: 'userName',
        component: () => import('@/views/globalView/Index'),
        meta: { title: '用户名', icon: 'el-icon-user-solid' }
      }
    ]
  },
  {
    path: '/nick',
    component: Layout,
    name: 'nick',
    children: [
      {
        path: 'name',
        name: 'nickname',
        component: () => import('@/views/nick/Nick'),
        meta: { title: '昵称', icon: 'el-icon-s-check' }
      }
    ]
  },
  {
    path: '/history',
    component: Layout,
    name: 'history',
    children: [
      {
        path: 'log',
        name: 'historyLog',
        component: () => import('@/views/history/Log'),
        meta: { title: '历史记录', icon: 'el-icon-time' }
      }
    ]
  },
  {
    path: '/history/log',
    component: Layout,
    name: 'historyLogPage',
    hidden: true,
    children: [
      {
        path: 'detail/:search_uid',
        name: 'historyDetail',
        component: () => import('@/views/history/Detail'),
        meta: { title: '历史详情', icon: 'el-icon-time' }
      }
    ]
  },

  // {
  //   path: '/administrators',
  //   component: Layout,
  //   name: 'administrators',
  //   children: [
  //     {
  //       path: 'page',
  //       name: 'administratorsPage',
  //       component: () => import('@/views/administrators/Index'),
  //       meta: { title: '管理员', icon: 'el-icon-s-tools' }
  //     }
  //   ]
  // },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/auth-login',
    name: 'AuthLogin',
    component: () => import('@/views/AuthLogin.vue')
  },
  {
    path: '/version',
    name: 'Version',
    component: () => import('@/views/Version.vue')
  },
  {
    path: '/401',
    name: '401',
    component: () => import('@/views/errorPage/401.vue')
  },
  {
    path: '*',
    name: '404',
    component: () => import('@/views/errorPage/404.vue')
  }
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
addRouteFun(router)

export default router
